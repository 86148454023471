import React from 'react'
import { useLocation } from "react-router-dom";
export default function Delivery() {
const location = useLocation();
  return (
    <div className="delivery-track">
                  <div className="delivery-track-img">
                    {location.state.ali.bookingHistory.find(
                      (ele) => ele.bookingStatusId === 1
                    )?.status ? (
                      <>
                      <img src="/imgs/Vector.webp" alt="tick" />
                      </>
                    ) : (
                      <img src="/imgs/Ellipse 26.webp" alt="tick" />
                    )}
                    
                    {location.state.ali.bookingHistory.find(
                      (ele) => ele.bookingStatusId === 1
                    )?.status  && location.state.ali.bookingHistory.find(
                      (ele) => ele.bookingStatusId === 2
                    )?.status?(
                      <div className="line"></div>
                    ) : (
                      <div className="no-line"></div>
                    )}
                    <p>Order Created</p>
                    <div className="date-time">
                    <p>
                      {
                        location.state.ali.bookingHistory.find(
                          (ele) => ele.bookingStatusId === 1
                        )?.date
                      }
                    </p>
                    <p>
                      {
                        location.state.ali?.bookingHistory.find(
                          (ele) => ele.bookingStatusId === 1
                        )?.time
                      }
                    </p>
                    </div>
                  </div>
                  <div className="delivery-track-img">
                    {location.state.ali.bookingHistory.find(
                      (ele) => ele.bookingStatusId === 2
                    )?.status ? (
                      <>
                      <img src="/imgs/Vector.webp" alt="tick" />
                      </>
                    ) : (
                      <img src="/imgs/Ellipse 26.webp" alt="tick" />
                    )}
 
                    {location.state.ali.bookingHistory.find(
                      (ele) => ele.bookingStatusId === 2
                    )?.status  && location.state.ali.bookingHistory.find(
                      (ele) => ele.bookingStatusId === 5
                    )?.status?(
                      <div className="line"></div>
                    ) : (
                      <div className="no-line"></div>
                    )}
                    <p>Accepted by Driver </p>
                    <div className="date-time">
                    <p>
                      {
                        location.state.ali?.bookingHistory.find(
                          (ele) => ele.bookingStatusId === 2
                        )?.date
                      }
                    </p>
                    <p>
                      {
                        location.state.ali?.bookingHistory.find(
                          (ele) => ele.bookingStatusId === 2
                        )?.time
                      }
                    </p>
                    </div>
                  </div>
                  <div className="delivery-track-img">
                    {location.state.ali.bookingHistory.find(
                      (ele) => ele.bookingStatusId === 5
                    )?.status ? (
                      <img src="/imgs/Vector.webp" alt="tick" />
                    ) : (
                      <img src="/imgs/Ellipse 26.webp" alt="tick" />
                    )}

                     
                    {location.state.ali.bookingHistory.find(
                      (ele) => ele.bookingStatusId === 5
                    )?.status  && location.state.ali.bookingHistory.find(
                      (ele) => ele.bookingStatusId === 6
                    )?.status?(
                      <div className="line"></div>
                    ) : (
                      <div className="no-line"></div>
                    )}
                    <p>Reached Warehouse</p>
                    <div className="date-time">
                    <p>
                      {
                        location.state.ali?.bookingHistory.find(
                          (ele) => ele.bookingStatusId === 5
                        )?.date
                      }
                    </p>
                    <p>
                      {
                        location.state.ali?.bookingHistory.find(
                          (ele) => ele.bookingStatusId === 5
                        )?.time
                      }
                    </p>
                    </div>
                  </div>
                  <div className="delivery-track-img">
                    {location.state.ali.bookingHistory.find(
                      (ele) => ele.bookingStatusId === 6
                    )?.status ? (
                      <img src="/imgs/Vector.webp" alt="tick" />
                    ) : (
                      <img src="/imgs/Ellipse 26.webp" alt="tick" />
                    )}
                     
                     {location.state.ali.bookingHistory.find(
                      (ele) => ele.bookingStatusId === 6
                    )?.status  && location.state.ali.bookingHistory.find(
                      (ele) => ele.bookingStatusId === 7
                    )?.status?(
                      <div className="line"></div>
                    ) : (
                      <div className="no-line"></div>
                    )}
                    <p>In Transit</p>
                    <div className="date-time">
                    <p>
                      {
                        location.state.ali?.bookingHistory.find(
                          (ele) => ele.bookingStatusId === 6
                        )?.date
                      }
                    </p>
                    <p>
                      {
                        location.state.ali?.bookingHistory.find(
                          (ele) => ele.bookingStatusId === 6
                        )?.time
                      }
                    </p>
                    </div>
                  </div>
                  <div className="delivery-track-img">
                    {location.state.ali.bookingHistory.find(
                      (ele) => ele.bookingStatusId === 7
                    )?.status ? (
                      <img src="/imgs/Vector.webp" alt="tick" />
                    ) : (
                      <img src="/imgs/Ellipse 26.webp" alt="tick" />
                    )}
                     
                     {location.state.ali.bookingHistory.find(
                      (ele) => ele.bookingStatusId === 7
                    )?.status  && location.state.ali.bookingHistory.find(
                      (ele) => ele.bookingStatusId === 8
                    )?.status?(
                      <div className="line"></div>
                    ) : (
                      <div className="no-line"></div>
                    )}
                    <p>Received at warehouse</p>
                    <div className="date-time">
                    <p>
                      {
                        location.state.ali?.bookingHistory.find(
                          (ele) => ele.bookingStatusId === 7
                        )?.date
                      }
                    </p>
                    <p>
                      {
                        location.state.ali?.bookingHistory.find(
                          (ele) => ele.bookingStatusId === 7
                        )?.time
                      }
                    </p>
                    </div>
                  </div>
                  <div className="delivery-track-img">
                    {location.state.ali.bookingHistory.find(
                      (ele) => ele.bookingStatusId === 8
                    )?.status ? (
                      <img src="/imgs/Vector.webp" alt="tick" />
                    ) : (
                      <img src="/imgs/Ellipse 26.webp" alt="tick" />
                    )}
                     
                     {location.state.ali.bookingHistory.find(
                      (ele) => ele.bookingStatusId === 8
                    )?.status  && location.state.ali.bookingHistory.find(
                      (ele) => ele.bookingStatusId === 13
                    )?.status?(
                      <div className="line"></div>
                    ) : (
                      <div className="no-line"></div>
                    )}
                    <p>Waiting to be Schedule</p>
                    <div className="date-time">
                    <p>
                      {
                        location.state.ali?.bookingHistory.find(
                          (ele) => ele.bookingStatusId === 8
                        )?.date
                      }
                    </p>
                    <p>
                      {
                        location.state.ali?.bookingHistory.find(
                          (ele) => ele.bookingStatusId === 8
                        )?.time
                      }
                    </p>
                    </div>
                  </div>
                  <div className="delivery-track-img">
                    {location.state.ali.bookingHistory.find(
                      (ele) => ele.bookingStatusId === 13
                    )?.status ? (
                      <img src="/imgs/Vector.webp" alt="tick" />
                    ) : (
                      <img src="/imgs/Ellipse 26.webp" alt="tick" />
                    )}
                     
                     {location.state.ali.bookingHistory.find(
                      (ele) => ele.bookingStatusId === 13
                    )?.status  && location.state.ali.bookingHistory.find(
                      (ele) => ele.bookingStatusId === 14
                    )?.status?(
                      <div className="line"></div>
                    ) : (
                      <div className="no-line"></div>
                    )}
                    <p>Pick UP</p>
                    <div className="date-time">
                    <p>
                      {
                        location.state.ali?.bookingHistory.find(
                          (ele) => ele.bookingStatusId === 13
                        )?.date
                      }
                    </p>
                    <p>
                      {
                        location.state.ali?.bookingHistory.find(
                          (ele) => ele.bookingStatusId === 13
                        )?.time
                      }
                    </p>
                    </div>
                  </div>
                  <div className="delivery-track-img">
                    {location.state.ali.bookingHistory.find(
                      (ele) => ele.bookingStatusId === 14
                    )?.status ? (
                      <img src="/imgs/Vector.webp" alt="tick" />
                    ) : (
                      <img src="/imgs/Ellipse 26.webp" alt="tick" />
                    )}
                    <p>Package delivered</p>
                    <div className="date-time">
                    <p>
                      {
                        location.state.ali?.bookingHistory.find(
                          (ele) => ele.bookingStatusId === 14
                        )?.date
                      }
                    </p>
                    <p>
                      {
                        location.state.ali?.bookingHistory.find(
                          (ele) => ele.bookingStatusId === 14
                        )?.time
                      }
                    </p>
                    </div>
                  </div>
                </div>
  )
}
