// @ts-nocheck
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PostAPI from "../../PostAPI";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { error_toaster, info_toaster, success_toaster } from "../Toaster";

export default function Hero() {
  const [trackId, setTrackId] = useState("");

  const [display, setDisplay] = useState(false);

  const closeModal = () => {
    setDisplay(false);
  };

  const navigate = useNavigate();

  const trackOrder = async (e) => {
    e.preventDefault();
    if (trackId === "") {
      info_toaster("Please enter your Tracking Id");
    } else {
      let res = await PostAPI("trackorder", {
        trackingId: trackId,
      });
      if (res?.data?.status === "1") {
        navigate("/Pickup", {
          state: {
            ali: res?.data?.data,
          },
        });
        success_toaster(res?.data?.message);
      } else {
        error_toaster(res?.data?.message);
      }
    }
  };

  return (
    <>
      <Modal isOpen={display} onClose={closeModal} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Error</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Please Enter Track ID</ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <section className="hero-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="hero-section-heading">
                <h2>Track all of your parcels in one place</h2>
                <p>No orders to show. Send your parcel to get statuses here</p>
              </div>

              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="XXXX XXXX XXXX"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  onChange={(e) => setTrackId(e.target.value)}
                  value={trackId}
                />
                <button
                  onClick={trackOrder}
                  className="input-group-text"
                  id="basic-addon2"
                >
                  Track
                </button>
              </div>
            </div>

            <div className="col-lg-6 d-flex align-items-end justify-content-center">
              <div className="hero-img">
                <img src="/imgs/hero.webp" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
