import { useLocation } from "react-router-dom";
import Navbar from "../Navbar";
import Delivery from "./Delivery";
export default function Pickup() {
  const location = useLocation();
  // const [data ,setData] = useState()

  // useEffect(() => {
  //   setData(location.state.ali)

  // }, [location.state.ali])
  // console.log(location.state.ali);

  return (
    <>
      <div className="background-white">
        <Navbar />
      </div>

      <section className="pickup-section">
        <div className="container-fluid px-lg-4 ">
          <div className="pickup-content">
            <div className="row">
              <div className="col-lg-8 px-lg-4">
                <div className="row">
                  <div className="col-lg-6 d-flex">
                    <div className="pickup-left-content">
                      <div className="pickup-heading">
                        <h4>Order#{location.state.ali?.trackingId}</h4>
                        <p>Order Picked Up</p>
                      </div>

                      <h4>Pickup</h4>
                      <span>
                        {location.state.ali?.deliveryDetails.pickupCode}
                      </span>
                      <p className="address">
                        {location.state.ali?.deliveryDetails.pickupAddress}
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-6 d-flex justify-content-lg-end text-lg-end">
                    <div className="pickup-right-content">
                      <div className="dropoff-heading">
                        <h4>Estd. Delivey </h4>
                        <p>{location.state.ali?.parcelDetails.ETA}</p>
                      </div>

                      <h4>Dropoff</h4>
                      <span>
                        {location.state.ali?.deliveryDetails.dropoffCode}
                      </span>
                      <p className="address">
                        {location.state.ali?.deliveryDetails.dropoffAddress}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row d-flex justify-content-between mt-lg-5">
                  <div className="col-lg-6">
                    <div className="parcel-details">
                      <div className="parcel-heading">
                        <img src="/imgs/parcel.webp" alt="" />
                        <h2>Parcel Details</h2>
                      </div>

                      <div className="parcel-content">
                        <h4>Type of shipment</h4>
                        <p>Package delivered</p>
                      </div>
                      <div className="parcel-content">
                        <h4>Category</h4>
                        <p>{location.state.ali?.parcelDetails.category}</p>
                      </div>
                      <div className="parcel-content">
                        <h4>Size</h4>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: location.state.ali?.parcelDetails.size,
                          }}
                        />
                      </div>
                      <div className="parcel-content">
                        <h4>Weight</h4>
                        <p>{location.state.ali?.parcelDetails.weight}</p>
                      </div>
                      <div className="parcel-content">
                        <h4>Distance</h4>
                        <p>{location.state.ali?.parcelDetails.distance}</p>
                      </div>
                      <div className="parcel-content">
                        <h4>Pickup Date</h4>
                        <p>{location.state.ali?.parcelDetails.pickupDate}</p>
                      </div>
                      {/* <div className="parcel-content">
                        <h4>ETA</h4>
                         <p>{location.state.ali?.parcelDetails.ETA}</p>
                      </div> */}
                      {/* <div className="parcel-content">
                    <h4>Order Amount</h4>
                    <p>{location.state.ali?.parcelDetails.orderTotal}</p>
                  </div> */}
                    </div>
                  </div>

                  <div className="col-lg-5">
                    <div className="delivery-details">
                      <div className="delivery-heading">
                        <img src="/imgs/delivery.webp" alt="" />
                        <h2>Delivery Details</h2>
                      </div>

                      <div className="delivery-content">
                        <div className="pcircle">
                          <div className="pickup-circle"></div>
                          <img src="/imgs/line 2.webp" alt="" />
                        </div>
                        <div className="pickup">
                          <h4>Pickup</h4>
                          <p>
                            {location.state.ali?.deliveryDetails.pickupCode}
                          </p>
                        </div>
                      </div>

                      <div className="delivery-content">
                        <div className="drop-circle"></div>
                        <div className="pickup">
                          <h4>Drop off</h4>
                          <p>
                            {location.state.ali?.deliveryDetails.dropoffCode}
                          </p>
                        </div>
                      </div>

                      <div className="delivery-heading">
                        <img src="/imgs/Invoice.webp" alt="" />
                        <h2>Invoice</h2>
                      </div>

                      <div className="invoice-content">
                        <h4>Subtotal</h4>
                        <p>{location.state.ali?.parcelDetails.subTotal}</p>
                      </div>
                      <div className="invoice-content">
                        <h4>Discount</h4>
                        <p>{location.state.ali?.parcelDetails.discount}</p>
                      </div>
                      <div className="invoice-content">
                        <h4 className="amount">Total Amount</h4>
                        <p className="amount">
                          {location.state.ali?.parcelDetails.orderTotal}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="col">
              <div className="driver-details">
                <div className="circle"></div>
                <div className="driver">
                  <h4>Driver Name </h4>
                  <p>GHK-165V</p>
                  <span>Vehical Name</span>
                </div>
              </div>
            </div> */}
              </div>

              <div className="col-lg-4">
                <Delivery></Delivery>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
