import React, { useState } from 'react';
import {useLocation } from 'react-router-dom';

const Navbar= () => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const location = useLocation().pathname

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  return (
    <nav className="navbar navbar-expand-lg">
        <div className="container">
      <a className="navbar-brand" href="/">
        <img src={`/imgs/${location === "/" ? "logo" : "logo2"}.webp`}  alt="Logo" className="navbr-logo" />
      </a>
      <button className="custom-toggler navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarsExample09">
        <a className="nav-link" href="/#">About</a>
        <a className="nav-link" href='/#'>Contact</a>
        <a href="/Sign in" className="nav-link sign-in" >Sign in</a>
      </div>
      </div>
    </nav>
  );
}

export default Navbar;
