import React from "react";
import Hero from "./Hero";
import Navbar from "../Navbar";

export default function Home() {
  return (
    <div>
      <div className="background-blue" >
        <Navbar />
        <Hero />
      </div>
    </div>
  );
}
