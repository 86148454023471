import axios from "axios";
const PostAPI = async (url, postData) => {
    let config = {
        headers: {
            accessToken: localStorage.getItem("accessToken")
        }
    }
    try {
        let response = await axios.post('https://backend.pps507.com/customer/' + url, postData, config)
        return response;
    }
    catch (error) { }
}
export default PostAPI