import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './Components/Home/Home';
import Pickup from './Components/Pickup/Pickup';
import { ChakraProvider } from '@chakra-ui/react'
import { ToastContainer } from 'react-toastify';
import NotFound from './Components/NotFound';
function App() {
  return (
    <>
      <ToastContainer />
      <ChakraProvider>
        <Router>
          <Routes>
            {/* AUTH */}
            <Route exact path='/' element={<Home />} />
            <Route exact path='/pickup' element={<Pickup />} />
            <Route  path='*' element={<NotFound />} />
          </Routes>
        </Router>
      </ChakraProvider>

    </>
  );
}

export default App;
